import { enUS, nl } from 'date-fns/locale';

export default {
    computed: {
        currentLocale() {
            return this.$i18n.localeProperties;
        },
        dateFnsLocale() {
            switch (this.currentLocale.code) {
            case 'en':
                return enUS;
            case 'nl':
            default:
                return nl;
            }
        }
    }
};
