import { isPast, isWithinInterval, isBefore, isValid } from 'date-fns';
import { formatInTimeZone } from 'date-fns-tz';

import datefnsLocale from '~/mixins/datefns-locale';

export default {
    mixins: [datefnsLocale],
    data() {
        return {
            startDate: null,
            endDate: null
        };
    },
    computed: {
        timeZonedStartDate() {
            if (!this.startDate) {
                return null;
            }

            return formatInTimeZone(this.startDate, 'Europe/Amsterdam', 'd MMM yyyy • HH:mm', { locale: this.dateFnsLocale });
        },
        timeZonedEndDate() {
            if (!this.endDate) {
                return null;
            }

            return formatInTimeZone(this.endDate, 'Europe/Amsterdam', 'd MMM yyyy • HH:mm', { locale: this.dateFnsLocale });
        },
        timeZonedNextStartDate() {
            if (!this.nextPeriod.starl) {
                return null;
            }

            return formatInTimeZone(this.nextPeriod.start, 'Europe/Amsterdam', 'd MMM yyyy • HH:mm', { locale: this.dateFnsLocale });
        },
    },
    methods: {
        isActivePeriod(periods) {
            const isActive = periods.find(({ start, end = null }) => {
                const offsetNow = new Date(Date.now());

                this.startDate = start ? new Date(start) : null;
                this.endDate = end ? new Date(end) : null;

                if (isBefore(this.endDate, this.startDate)) {
                    return false;
                }

                if (isPast(this.startDate) && !this.endDate) {
                    return true;
                }

                const interval = {
                    start: this.startDate,
                    end: this.endDate
                };

                if (isValid(interval?.end)) {
                    return isWithinInterval(offsetNow, interval);
                }

                return isPast(interval.start);
            });
            return !!isActive;
        }
    }
};
