<template>
    <NuxtLink :to="localePath(`/${subsidy.uri}`)" class="subsidy-card" :class="`subsidy-card--${status}`">
        <h3 class="subsidy-card__title">{{ subsidy.title }}</h3>
        <SubsidyStatus :subsidy-periods="subsidy?.periodsRelation" @status="updateStatus" />
        <BaseTag class="subsidy-card__tag" :text="$t('subsidies.subsidy')" data-outline />
    </NuxtLink>
</template>

<script>
export default {
    props: {
        subsidy: {
            type: Object,
            required: true
        }
    },
    data() {
        return {
            status: 'open'
        };
    },
    methods: {
        updateStatus(status) {
            this.status = status;
        }
    }
};
</script>

<style lang="less" src="./SubsidyCard.less"></style>
