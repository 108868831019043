<template>
    <div class="subsidy-status" :class="{'subsidy-status--open': isCurrentPeriod}">
        <div class="sr-only">Status: </div>
        <span v-if="isCurrentPeriod">
            <template v-if="currentPeriod.end">
                <b>{{ $t('subsidies.status.open_until') }}</b> <time :datetime="timeZonedEndDate" class="subsidy-card__date">{{ timeZonedEndDate }}</time>
            </template>
            <template v-else>
                <b>{{ $t('subsidies.status.open') }}</b>
            </template>
        </span>
        <span v-else-if="nextPeriod?.start">
            <b>{{ $t('subsidies.status.closed_until') }}</b> <time :datetime="timeZonedStartDate" class="subsidy-card__date">{{ timeZonedStartDate }}</time>
        </span>
        <span v-else>
            <b>{{ $t('subsidies.status.closed') }}</b>
        </span>
    </div>
</template>

<script>
import { isFuture, parseISO, lightFormat } from 'date-fns';

import subsidyMixin from '~/mixins/subsidy';

export default {
    mixins: [subsidyMixin],
    props: {
        subsidyPeriods: {
            type: Array,
            default() { return []; }
        }
    },
    emits: ['status'],
    data() {
        return {
            isCurrentPeriod: false,
            activeInterval: null
        };
    },
    computed: {
        currentPeriod() {
            if (this.isCurrentPeriod) {
                return this?.subsidyPeriods?.[0];
            }
            return null;
        },
        nextPeriod() {
            return this?.subsidyPeriods?.find(({ start }) => {
                return isFuture(parseISO(start));
            });
        },
        status() {
            if (this.isCurrentPeriod) {
                return 'open';
            }
            return 'closed';
        }
    },
    watch: {
        status: {
            handler(newValue) {
                this.$emit('status', newValue);
            },
            immediate: true
        }
    },
    mounted() {
        this.isCurrentPeriod = this.isActivePeriod(this?.subsidyPeriods);

        this.activeInterval = setInterval(() => {
            this.isCurrentPeriod = this.isActivePeriod(this?.subsidyPeriods);
        }, 1000);
    },
    unmounted() {
        clearInterval(this.activeInterval);
    },
    methods: {
        parseISO,
        lightFormat
    }
};
</script>

<style lang="less" src="./SubsidyStatus.less"></style>
